// import $ from 'jquery';
import BaseView from 'picnic/core/views/Base';
import {injectScript} from 'app/utils/inject';

// This self invoked function creates `window.uga` function
// And sets `window.uga` function as default `GoogleAnalyticsObject`
// The name `uga` is necessary, because @kleinanzeigen/google-analytics uses that name
(function () {
	const gaFunctionName = 'uga';

	function ugaFunction(...args) {
	 	(window[gaFunctionName].q = window[gaFunctionName].q || []).push(args);
	}

	try {
		window.GoogleAnalyticsObject = gaFunctionName;

		// window[gaFunctionName].q is referring to queue of Google Analytics library
		// and it is processed when GA library is loaded
		window[gaFunctionName] = window[gaFunctionName] || ugaFunction;
		window[gaFunctionName].l = 1 * new Date();
	} catch {
		//
	}
})();

class View extends BaseView {

	render() {
		super.render();

		const src = this.$el.data('script');

		if (src) {
			injectScript(src);
		}

		return this;
	}

}

export default View;
