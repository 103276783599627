import $ from 'jquery';
// import {TrackPageview, TrackSocial} from 'picnic/TrackingAnalytics';
import AnalyticsInitialize from './AnalyticsInitialize';
import TrackEvent from './TrackEvent';
import RegistryService from '../services/Registry.js';
import OutboundService from 'picnic/TrackingOutbound';


var
	NAMESPACE = 'tracking:',
	NAMESPACE_REGISTRY = NAMESPACE + 'registryservice',
	NAMESPACE_OUTBOUND = NAMESPACE + 'outboundservice',
	// EVENT_TRACKPAGEVIEW = NAMESPACE + 'trackpageview',
	EVENT_TRACKEVENT = NAMESPACE + 'trackevent',
	// EVENT_TRACKSOCIAL = NAMESPACE + 'tracksocial',
	SELECTOR_OUTBOUND = 'a',
	SELECTOR_CUSTOMOUTBOUND = '.custom-tracking',
	OUTBOUND_EVENT_CATEGORY = 'outbound',
	OUTBOUND_EVENT_ACTION = 'link'
;


class Command extends AnalyticsInitialize {
	execute() {
		this._registry = new RegistryService({context: this.context});

		// Wire registry...
		this.context.wireValue(NAMESPACE_REGISTRY, this._registry);

		// Initialize google analytics...
		super.execute();

		// Wire commands for analytics tracking:
		// this.context.wireCommand(EVENT_TRACKPAGEVIEW, TrackPageview);
		this.context.wireCommand(EVENT_TRACKEVENT, TrackEvent);
		// this.context.wireCommand(EVENT_TRACKSOCIAL, TrackSocial);

		// Create outbound service:
		// The outbound service watches all links on a page and when they
		// are clicked, it checks whether it is an internal link or an
		// external link (outbound). In the case of an external link, it
		// fires an event which should be tracked...
		this.context.wireValue(NAMESPACE_OUTBOUND, new OutboundService({
			context: this.context,
			selector: SELECTOR_OUTBOUND,
			eventName: EVENT_TRACKEVENT,
			eventData: {
				category: OUTBOUND_EVENT_CATEGORY,
				action: OUTBOUND_EVENT_ACTION
			}
		}));

		$('body').on('click.custom-tracking', SELECTOR_CUSTOMOUTBOUND, (event) => {
			window.dataLayer.push({
				event: 'gaEvent',
				eventAction: event.currentTarget.getAttribute('data-category') || window.kleinanzeigen.trackingIdentifier,
				eventCategory: event.currentTarget.getAttribute('data-action'),
				eventLabel: event.currentTarget.getAttribute('data-label'),
				fieldsObject: {
					'dimension1': window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK',
				}
			});
		});

		$('body').on('focusout.input-tracking', 'input', (event) => {
			var element = event.currentTarget,
				category,
				action,
				baseLabel,
				inputLength,
				label;
			if (!$(element).hasClass('input-tracking')) {
				element = $(event.currentTarget).parents('.input-tracking')[0];
			}
			if (element) {
				category = element.getAttribute('data-category');
				action = element.getAttribute('data-action');
				baseLabel = element.getAttribute('data-label');
				inputLength = event.currentTarget.value.length;
				label = baseLabel + inputLength;
				window.dataLayer.push({
					event: 'gaEvent',
					eventAction: action,
					eventCategory: category,
					eventLabel: label,
					fieldsObject: {
						'dimension1': window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK',
					}
				});
			}
		});

		this._registerAccordion();
		this._registerWizard();
		this._registerContact();
		this._registerSlideshow();
	}

	_registerAccordion() {
		this._registry.registerEvent('cms:accordion:toggle', {
			category: '.category',
			action: '.action',
			label: '.label',
			fieldsObject: {
				'dimension1': window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK'
			}
		});
	}

	_registerWizard() {
		this._registry.registerEvent('realestate:wizard:submit', {
			category: ({dataCategory}) => dataCategory,
			action: ({dataAction}) => dataAction,
			label: ({dataLabel}) => dataLabel,
			fieldsObject: {
				'dimension1': window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK'
			}
		});
	}

	_registerContact() {
		this._registry.registerEvent('contact:success', {
			category: function(data) {
				if (data.dataCategory) {
					return data.dataCategory;
				}
				if (data.category === 'pro' || data.category === 'real-estate') {
					return `LandingPage${((data.category === 'pro') ? 'Pro' : 'RealEstate')}Lead`;
				}
				return window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK';
			},
			action: 'Web2LeadSentAttempt',
			label: function(data) {
				if (data.dataLabel) {
					return data.dataLabel;
				}
				if (data.category === 'pro' || data.category === 'real-estate') {
					return (data.category === 'pro') ? 'Pro' : 'RE_B2C';
				}
			},
			fieldsObject: {
				'dimension1': window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK'
			}
		});

		this._registry.registerEvent('contact:removesuccess', {
			category: window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK',
			action: '.action',
			label: '.label',
			fieldsObject: {
				'dimension1': window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK'
			}
		});

		this._registry.registerEvent('contact:exitintent', {
			category: window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK',
			action: 'UserInactive',
			label: '0',
			fieldsObject: {
				'dimension1': window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK'
			}
		});
	}

	_registerSlideshow() {
		this._registry.registerEvent('slideshow:change', {
			category: window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK',
			action: 'CarouselClick',
			label: 'Advantage'
		});
	}
}

export default Command;
